<template>
  <b-row>
    <b-col>
      <b-form-group
        label="Satış Danışmanı"
        label-for="id_com_user"
      >
        <v-select
          id="id_com_user"
          v-model="filters.id_com_user"
          :options="users"
          :reduce="item => item.id"
          label="title"
          placeholder="Seçiniz"
          @input="filterAction"
        />
      </b-form-group>
    </b-col>
    <b-col>
      <b-form-group
        label="Durum"
        label-for="status"
      >
        <v-select
          id="status"
          v-model="filters.status"
          :options="statuses"
          :reduce="item => item.id"
          label="title"
          placeholder="Seçiniz"
          @input="filterAction"
        />
      </b-form-group>
    </b-col>
  </b-row>
</template>

<script>
import { BRow, BCol, BFormGroup } from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  name: 'FilterData',
  components: {
    BRow,
    BCol,
    BFormGroup,
    vSelect,
  },
  props: {
    filterAction: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      statuses: [
        { id: 0, title: 'Beklemede' },
        { id: 1, title: 'Tamamlandı' },
      ],
    }
  },
  computed: {
    filters() {
      return this.$store.getters['webformG2saleform/filters']
    },
    users() {
      return this.$store.getters['users/getUsers']
    },
  },
  created() {
    this.getUsers()
  },
  methods: {
    getUsers() {
      this.$store.dispatch('users/usersList', {
        select: [
          'com_user.id AS id',
          'com_user.name AS title',
        ],
        where: {
          'com_user.status': 1,
          'com_user.id_com_department': 2,
          'com_user.id_com_brand': 6,
        },
      })
    },
  },
}
</script>
